.content_parcour {
    width: 100%;
}

.item_parcour {
    display: flex;
    align-items: center;
    justify-content: center;
}

.item_parcour h2 {
    font-family: Font-bold, sans-serif;
    color: #4498d3;
    font-size: 30px;
}

.item_parcour h3 {
    font-family: Font-regular, sans-serif;
    color: #cbdcf5;
    font-size: 25px;
}

.item_parcour p {
    font-family: Font-ExtraLight, sans-serif;
    color: #cbdcf5;
    margin-bottom: 10px;
}

.item_parcour ul {
    font-family: Font-ExtraLight, sans-serif;
    color: #cbdcf5;
    margin-left: 20px;
    list-style-type: none;
}


.item_parcour > div {
    width: 45%;
    padding: 20px 0;
}

.item_parcour:nth-child(2n) > div:nth-child(2n) {
    position: relative;
    border-left: 1px solid #cbdcf5;
    padding-left: 30px;
}

.item_parcour:nth-child(2n + 1) > div:nth-child(2n - 1) {
    position: relative;
    border-right: 1px solid #cbdcf5;
    transform: translateX(1px);
    padding-right: 30px;
}

.item_parcour:nth-child(2n) > div:nth-child(2n)::after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #cbdcf5;
    border-radius: 50%;
    top: 50%;
    left: -8px;
}

.item_parcour:nth-child(2n + 1) > div:nth-child(2n - 1)::after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #cbdcf5;
    border-radius: 50%;
    top: 50%;
    right: -7.5px;
}

@media screen and (max-width: 700px) {

    .item_parcour:nth-child(2n + 1) > div:nth-child(2n - 1)::after {
        top: 50%;
        left: -7.5px;
    }

    .item_parcour {
        justify-content: left;
    }

    .item_parcour:nth-child(2n) > div:nth-child(2n) {
        width: 90%;
    }

    .item_parcour:nth-child(2n + 1) > div:nth-child(2n - 1) {
        border-right: none;
        border-left: 1px solid #cbdcf5;
        padding-left: 30px;
        transform: translate(-.5px);
        width: 90%;
    }

    .item_parcour:nth-child(2n) > div:nth-child(2n - 1) {
        width: 0;
    }

    .item_parcour:nth-child(2n + 1) > div:nth-child(2n) {
        width: 0;
    }
}