.content_contact {
    margin: 0 0 100px;
}

.contact {
    background-color: #1a1a4d;
    border-radius: 10px;
    overflow-x: scroll;
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
}

.line {
    margin-top: 12px;
}

.methodName {
    color: #82AAFF;
}

.identifier {
    color: #C792EA;
    font-style: italic;
}

.characters {
    color: #FFCB6B;
}

.acolade {
    color: white;
}

.string {
    color: #C3E88D;
}

.variable {
    color: white;
}

.operator {
    color: #89DDFF;
}

.commentaire {
    color: #616161;
}

.datetime {
    color: white;
}

.link {
    color: #C3E88D;
    transition: .1s ease-in-out color;
}

.link:hover {
    color: white;
}

.top_item_contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #9da0bb;
    border-bottom: 1px solid rgba(14, 14, 28, 0.23);
    width: 100%;

    .left_contact {
        padding: 5px 20px;
        display: flex;
        justify-content: start;
        align-items: center;
        background-color: #0e0a3d;
        font-size: 20px;

        img {
            margin-left: 10px;
            width: 15px;
            background-color: rgba(255, 255, 255, 0.09);
            border-radius: 50%;
            cursor: pointer;
            padding: 2px;
        }

        img:hover {
            background-color: rgba(255, 255, 255, 0.25);
        }
    }

    .close_panel {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;

        div {
            cursor: pointer;
            margin: 0 3px;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            position: relative;
        }

        .close_item_1 {
            background-color: #ff5f5a;
        }

        .close_item_2 {
            background-color: #ffbe2e;
        }

        .close_item_3 {
            background-color: #2aca44;
        }
    }
}

.code {
    margin-right: 30px;
    display: flex;
    justify-content: center;
    align-items: start;
    font-family: Code, sans-serif !important;
    font-size: 17px;
    min-width: 900px;
    .numbers {
        margin-right: 15px;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: end;
        flex-direction: column;
        color: rgba(255, 255, 255, 0.1);

        div {
            display: flex;
            justify-content: start;
            align-items: center;
            flex-direction: row;
        }

        .start {
            width: 20px;
        }

        img {
            margin-left: 10px;
            width: 20px;
            cursor: pointer;
        }
    }
}

.console {

    width: 100%;
    .content_console {
        background-color: rgba(14, 14, 28, 0.35);
        padding: 10px;
        max-height: 200px;
        overflow-y: scroll;
    }
}