body {

}

.container_home {
  background-position: top;
  background-size: cover;
}

.image_programming {
  width: 500px;
}

.content {
  padding-top: 50px;
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.content h1 {
  margin-top: 20px;
  color: #cbdcf5;
  font-size: 50px;
  font-family: Font-bold, sans-serif;
}

@media screen and (max-width: 700px) {
  .image_programming {
    width: 70%;
  }

  .content h1 {
    font-size: 35px;
  }
}

.mon_nom {
  margin: 0 10px;
  background-image: linear-gradient(to right, #bc4e9c, #f80759);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}