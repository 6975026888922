.content_competence {

  display: flex;
  flex-wrap: wrap;
  justify-content: center;


  .item_competence {
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    border-radius: 50px 15px;
    background: linear-gradient(145deg, #09094b, #0e0a3d);
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.4);
  }

  img {
    width: 80px;
    margin: 30px;
    border-radius: 5px;
  }
}


