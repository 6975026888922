.content_passion {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}

.special img {
  margin-top: 30px;
}

.special p {
  margin-bottom: 30px;
}


.content_passion > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all .2s ease-in-out;
  padding: 60px 50px;
  background: linear-gradient(#0e0e48, #0e0e48) padding-box,
  linear-gradient(to right, #4568dc, #b06ab3) border-box;
  border: 3px solid transparent;
  box-shadow: inset -10px -10px 26px rgba(0, 0, 0, 0.46),
  10px 10px 100px 10px rgba(255, 255, 255, 0.16);

  &:hover {
    box-shadow: inset -10px -10px 26px rgba(0, 0, 0, 0.46),
    10px 10px 100px 10px rgba(255, 255, 255, 0.29);
  }
}

.content_passion h2 {
  color: #9da0bb;
  font-size: 30px;
  margin-top: 20px;
}

.content_passion p {
  color: #3f5f91;
  font-style: italic;
  font-size: 20px;
  transition: all .2s ease-in-out;
  margin-top: 20px;
}

.content_passion .custom_w {
  width: 200px;
  border-radius: 50px;
}

.content_passion img {
  width: 100px;
}

@media screen and (max-width: 1520px) {
  .content_passion {
    flex-direction: column;
  }

  .content_passion > div {
    width: 550px;
    margin-top: 20px;
  }
}

.content_passion > div:hover p {
  color: #9da0bb;
}

@media screen and (max-width: 700px) {
  .content_passion {
    flex-direction: column;
  }

  .content_passion > div {
    width: 100%;
    margin-top: 20px;
  }
}

