.btn_menu {
    position: fixed;
    top: 10px;
    right: 20px;
    transition: all .2s linear;
    cursor: pointer;
}

.content_navbar {
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    transition: all .3s linear;
    background-color: #1f1f47;
    z-index: 79;
    box-shadow: 1px 1px 30px 5px #283a54;
}

.content_navbar h1 {
    color: #cbdcf5;
    margin-left: 10px;
    margin-top: 80px;
    text-align: center;
    font-family: Font-bold, sans-serif;
}

.content_navbar img {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 20px;
}

.content_item_nav {
    display: flex;
    flex-direction: column;
    background-color: #242456;
    border: 1px solid #15153a;
    border-radius: 5px;
    padding: 10px 0;
    margin: 40px 10px 0 10px;
}

.content_item_nav h2 {
    margin-left: 20px;
    color: #3c4472;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
}

.content_item_nav a {
    color: #cbdcf5;
    text-align: left;
    text-decoration: none;
    font-family: Font-regular, sans-serif;
    width: 100%;
    transition: all .2s linear;
    padding: 20px 20px;
}

.content_item_nav a:hover {
    background-color: #3f5f91;
}

.content_item_nav p {
    color: #959ba2;
    font-size: 15px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 20px;
}

.menu_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}