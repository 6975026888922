.container_time {
    margin-top: 50px;
    margin-bottom: 50px;
}

.clock {
    width: 250px;
    height: 250px;
    border: 1px solid rgba(255, 255, 255, 0.11);
    border-radius: 50%;
    box-shadow: 15px 15px 30px rgba(26, 26, 60, 0.42),
    -15px -15px 30px rgba(36, 36, 82, 0.3);
    position: relative;
}

.clock::before {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    box-shadow: inset 15px 15px 30px rgba(26, 26, 60, 0.51),
    inset -15px -15px 30px rgba(36, 36, 82, 0.42);
    z-index: 2;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.clock::after {
    content: "";
    top: 50%;
    left: 50%;
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #3f5f91;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.time_num {
    color: #3f5f91;
    font-size: 25px;
    font-weight: bold;
    z-index: 15;
    position: absolute;
}

.top {
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
}

.left {
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
}

.right {
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
}

.bottom {
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
}

.content_line_time {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 50%;
    left: 50%;
}

.content_line_time > div {
    border-radius: 10px;
}

.content_h > div {
    width: 5px;
    height: 60px;
}

.content_m > div {
    width: 3px;
    height: 70px;
}

.content_s > div {
    width: 2px;
    height: 90px;
}

.h {
    background-color: #0da6de;
}

.m {
    background-color: #ffffff;
}

.s {
    background-color: #3f5f91;
}

.step {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90%;
    z-index: 10;
    position: absolute;
    top: 50%;
    left: 50%;
}

.step > div {
    width: 2px;
    height: 10px;
    border-radius: 5px;
    background-color: #3f5f91;
}

.step1 {
    transform: translate(-50%, -50%) rotate(30deg);
}

.step2 {
    transform: translate(-50%, -50%) rotate(60deg);
}

.step3 {
    transform: translate(-50%, -50%) rotate(120deg);
}

.step4 {
    transform: translate(-50%, -50%) rotate(150deg);
}

