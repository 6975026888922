.container_bf {
    width: 100%;
    margin: 50px 0 50px 0;
    color: #959ba2;
    padding-top: 50px;
    border-top: 1px solid #183b61;
}

.container_bf > img {
    width: 150px;
}

.container_bf h2 {
    margin-top: 50px;
    margin-bottom: 50px;
    font-style: italic;
}