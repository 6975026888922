.contentMouse {
  margin-top: 40px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contentMouse p {
  font-style: italic;
  margin-top: 15px;
  color: #9da0bb;
}

.mouse {
  position: relative;
  width: 26px;
  height: 50px;
  border: 2px solid #9da0bb;
  border-radius: 10px;
}

.mouse::before {
  content: "";
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  top: 5px;
  height: 14px;
  width: 14px;
  background-color: #9da0bb;
  border-radius: 5px;
  animation: animateScrollDown infinite 1s;
}

@keyframes animateScrollDown {
  0% {
    transform: translate(-50%, 0px);
  }
  50% {
    transform: translate(-50%, 20px);
  }
  100% {
    transform: translate(-50%, 0px);
  }
}