.container_past {
    margin: 50px 0 10px 0;
}

.container_past p {
    margin-top: 20px;
    border: 1px solid #cbdcf5;
    padding: 2px 15px;
    background-color: #f0eefd;
    text-transform: uppercase;
    color: #0da6de;
    font-family: Font-regular, sans-serif;
    font-weight: 500;
    border-radius: 3px;
}