.container_goTop {
    position: fixed;
    right: 20px;
    bottom: 30px;
    display: none;
}

.visible {
    display: block;
}

.notVisible {
    display: none;
}
