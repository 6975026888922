.container_moi {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.container_moi h1 {
  font-size: 40px;
  color: #c9d4ea;
}

.chiffres {
  margin: 100px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  width: 100%;
  background-color: rgb(3, 3, 47, .9);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  box-shadow: inset 0 0 40px 10px rgba(255, 255, 255, 0.05);
  padding: 10px;

  .chiffre {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 230px;
    color: #c9d4ea;
    margin: 20px 10px;

    p {
      font-size: 40px;
    }

    h2 {
      font-size: 20px;
      font-style: italic;
      color: #778bbd;
    }

    img {
      width: 30px;
    }
  }
}

.container_moi > div {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.cv {
  margin: 150px 0 70px;
  padding: 20px 60px;
  background-color: #3f5f91;
  color: #ffffff;
  font-size: 20px;
  text-decoration: none;
  outline: none;
  border-radius: 5px;
  transition: all .2s ease;
}

.cv:hover {
  background-color: #2f2f61;
}

.text {
  font-size: 20px;
  color: #9da0bb;
  text-align: center;
}

.separator {
  position: relative;
  width: 50px;
  height: 2px;
  background-color: #4e92b9;
  margin: 20px 0 50px 0;
  border-radius: 2px;
  transform: translateX(-20px);
}

.separator::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 2px;
  background-color: #4e92b9;
  border-radius: 2px;
  top: 0;
  right: -25px;
}

.hoverEffect {
  -webkit-box-shadow: 0 0 50px 50px rgba(255, 255, 255, 0.38);
  box-shadow: 0 0 50px 50px rgba(255, 255, 255, 0.38);
  content: "";
  height: 1px;
  position: absolute;
}

@media screen and (max-width: 10000px) {
  .container_moi > div {
    width: 80%;
  }
}

@media screen and (max-width: 700px) {

}

@media screen and (max-width: 500px) {
  .buble1 {
    top: -50px !important;
    left: 20px !important;
    width: 70px !important;
    height: 70px !important;
  }

  .buble2 {
    bottom: -50px !important;
    right: 20px !important;
    width: 70px !important;
    height: 70px !important;
  }

  .buble3 {
    top: -40px !important;
    right: -20px !important;
    width: 70px !important;
    height: 70px !important;
  }

  .buble4 {
    bottom: -20px !important;
    left: 40px !important;
    width: 70px !important;
    height: 70px !important;
  }
}

.card {
  z-index: 0;
  position: relative;
  width: 300px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  margin: 100px 0 150px 0;
}

.card div:first-child {
  z-index: 10;
  display: flex;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}

.card img {
  width: 150px;
  margin: 25px 0;
}

.card h1 {
  font-size: 25px;
  font-family: Font-bold, sans-serif;
}

.card p {
  color: #cbdcf5;
  margin: 25px 0;
}

.buble {
  z-index: -10;
  position: absolute;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.buble1 {
  top: -40px;
  background: rgba(125, 171, 243, 0.25);
  left: -60px;
  width: 100px;
  border-radius: 50%;
  height: 100px;
}

.buble2 {
  bottom: -60px;
  background: rgba(180, 149, 215, 0.25);
  right: -80px;
  width: 140px;
  border-radius: 9px;
  height: 140px;
}

.buble3 {
  top: -80px;
  background: rgba(173, 215, 149, 0.25);
  right: -40px;
  width: 140px;
  height: 140px;
  border-radius: 50px;
}

.buble4 {
  bottom: -80px;
  background: rgba(215, 149, 192, 0.25);
  left: -90px;
  width: 140px;
  height: 140px;
  border-radius: 25px;
}

.bg_bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  transform: rotate(180deg);
  z-index: -10;
}

.bold {
  position: relative;
  font-weight: bolder;
}

.bold::before {
  content: "";
  position: absolute;
}

.under {
  position: relative;
  padding: 2px;
  transition: all .2s ease-in-out;
  /*text-decoration: underline;*/
}

.under::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: #9b9fb7;
  bottom: 0;
  left: 0;
  z-index: -1;
  transition: all .2s ease-in-out;
}

.text:hover .under::before {
  height: 100%;
}

.text:hover .under {
  color: #1a1a3c;
}

.under:nth-child(2n), .under:nth-child(2n)::before {
  transition-delay: .07s;
}

.under:last-child, .under:last-child::before {
  transition-delay: .1s;
}
