* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  overflow-anchor: none;
}

@font-face {
  font-family: "Font-ExtraLight";
  src: url("./Font/Nunito-ExtraLight.ttf");
}

@font-face {
  font-family: "Font-bold";
  src: url("./Font/Nunito-Bold.ttf");
}

@font-face {
  font-family: "Code";
  src: url("./Font/Menlo-Regular.ttf");
}

@font-face {
  font-family: "Font-regular";
  src: url("./Font/Nunito-Regular.ttf");
}

@font-face {
  font-family: "Code Light";
  src: url("./Font/Ubuntu-Light.ttf");
}

@font-face {
  font-family: "Code Regular";
  src: url("./Font/Ubuntu-Regular.ttf");
}


body {
  background: #050536;
  font-family: Font-regular, sans-serif;
}

.back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -10;
}

.bot {
  position: absolute;
  transform: rotate(180deg);
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -10;
}

.titre {
  text-align: center;
  display: flex;
}

.blur_item {
  position: relative;
}

.blur_item::before {
  content: '';
  z-index: -1;
  border-radius: 20px;
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 50px;
  background: var(--blur-color);
  box-shadow: 0 0 150px 300px var(--blur-color);
}

:root {
  --blur-color: rgba(44, 0, 73, 0.74);
}