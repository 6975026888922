.item_travaux {
    position: relative;
    width: 100%;
    height: 200px;
    background-color: #cbdcf5;
    overflow: hidden;
    border-radius: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
}

.type {
    position: absolute;
    font-family: Font-regular, sans-serif;
    padding: 3px 20px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    top: 0;
    left: 10px;
    transition: all .3s ease-in-out;
    color: #ffffff;
}

.inside_type {
    position: absolute;
    font-family: Font-regular, sans-serif;
    background-color: #2097ec;
    padding: 3px 20px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    top: 0;
    transform: translateX(-50%);
    left: 50%;
    color: #ffffff;
}

.titre_part {
    transform: translateY(20px);
    transition: all .3s ease-in-out;
    opacity: 1;
    font-family: Font-bold, sans-serif;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.titre_part h4 {
    font-size: 18px;
    font-weight: lighter;
    margin-top: 10px;
    color: rgba(60, 68, 114, 0.65);
    font-style: italic;
}

.titre_part img:hover {
    background-color: #4e92b9;
}

.item_travaux_content {
    cursor: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 80%;
    background-color: #050d31;
    z-index: 50;
    transition: all .3s ease-in-out;
    border-radius: 5px;
    box-shadow: 5px 5px 20px 1px #0e0e1c;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #cbdcf5;
}

.bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all .3s ease-in-out;
}

.item_travaux_content > div {
    width: 80%;
}

.content_pastille {
    display: flex;
    justify-content: left;
    flex-direction: row;
    align-items: center;
}

.content_pastille > div {
    color: #ffffff;
    font-family: Font-bold, sans-serif;
    padding: 3px 10px;
    border-radius: 10px;
    margin: 10px 10px;
}


.item_travaux_content img {
    position: absolute;
    top: 10px;
    right: 10px;
    transition: all .2s ease-in-out;
    cursor: pointer;
    border-radius: 50%;
    z-index: 60;
}

.item_travaux_content img:hover {
    background-color: rgba(203, 220, 245, 0.17);
}

.num {
    z-index: 2;
    position: absolute;
    opacity: 0;
    transform: translateY(-50%);
    transition: all .2s ease-in-out;
    background-color: rgba(5, 16, 33, 0.67);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.titre_item_travaux {
    transition: all .3s ease-in-out;
}

.voir_plus {
    transition: all .3s ease-in-out;
}

.voir_plus h4 {
    transition: all .3s ease-in-out;
}

.item_travaux:hover .titre_item_travaux {
    transform: scale(50%) translateY(-50%);
    opacity: 0;
}

.item_travaux:hover .voir_plus {
    transform: scale(150%) translateY(-50%);
}

.item_travaux:hover .voir_plus h4 {
    color: #000;
}

.item_travaux:hover .num {
    transform: translateY(0);
    opacity: 1;
}

.item_travaux_content h2 {
    font-family: Font-bold, sans-serif;
    font-size: 30px;
}

.item_travaux_content ul {
    list-style-type: none;
    margin-left: 20px;
    font-size: 17px;
    font-family: Font-ExtraLight, sans-serif;
}

.item_travaux_content p {
    margin-top: 10px;
    font-size: 17px;
    font-family: Font-ExtraLight, sans-serif;
}

.item_travaux_content h3 {
    font-family: Font-regular, sans-serif;
    font-size: 25px;
    margin-top: 30px;
}

@media screen and (max-width: 1000px) {

    .item_travaux_content {
        width: 95%;
    }
}


.content_travaux {
    color: #9da0bb;
    width: 80%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.travail {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    border: 2px solid #3c4472;
    padding: 10px 40px;
    background-color: rgba(60, 68, 114, 0.24);
    border-radius: 5px;
}

.travail .img_travail {
    padding-top: 50px;
    width: 50%;
    position: relative;
}

.imgSelected {
    z-index: 40;
    animation: arrival .4s;
}

@keyframes arrival {
    50% {
        transform: scale(1.05) translateX(30px) rotate(3deg);
    }
}

.travail .img_travail img {
    margin-top: 20px;
    transition: all .3s ease-in-out;
    object-fit: cover;
    width: 100%;
    position: absolute;
    border-radius: 5px;
}

.travail .img_travail img:nth-child(1) {
    transform: rotate(0deg);
}

.travail .img_travail img:nth-child(2) {
    transform: rotate(1deg);
}

.travail .img_travail img:nth-child(3) {
    transform: rotate(-1deg);
}


.right_travail {

    width: 50%;
    padding: 20px 10px 0 20px;

    .top_travail {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        margin-bottom: 10px;
        h2 {
            color: #4b5392;
            font-size: 20px;
            margin-left: 20px;
        }
        .img_top_travail {
            width: 25px;
        }
    }

    .titre_travail {

        margin-top: 30px;
        h1 {
            font-weight: bolder;
            color: #0da6de;
            margin-bottom: 20px;
        }
        p {
            color: #9b9fb7;
            font-size: 19px;
            font-style: italic;
        }
    }

    .item_travail {
        margin-top: 30px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        padding: 30px 0;


        .select0 .img_item_travail {
            background-color: rgba(239, 183, 106, 0.59);
            border: 2px solid #ef900e !important;
        }

        .select1 .img_item_travail {
            background-color: rgba(151, 239, 92, 0.51);
            border: 2px solid #3ecc0a !important;
        }

        .select2 .img_item_travail {
            background-color: rgba(176, 66, 232, 0.49);
            border: 2px solid #a510bc !important;
        }

        .item_travail_item {
            cursor: pointer;
            width: 30%;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            transition: all .3s ease-in-out;

            h2 {
                font-size: 15px;
                margin-top: 30px;
                text-align: center;
            }

            .img_item_travail {
                width: 80px;
                height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                border: 2px dashed transparent;
                border-radius: 5px;
                transition: all .3s ease-in-out;

            }

            .img_item_travail:hover {
                border: 2px dashed #9b9fb7;
            }
        }
    }

    .item_travail::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(rgba(155, 159, 183, 0.20) 1px,transparent 1px),linear-gradient(to right,rgba(155, 159, 183, 0.20) 1px,hsla(0,0%,100%,0) 1px);
        background-position: -4px 8px;
        border-radius: 10% 90% 7% 93% / 87% 8% 92% 13%;
        background-size: 28px 30px;
        z-index: -1;
    }
}

@media screen and (max-width: 1500px) {

    .travaux {
        width: 90% !important;
    }

    .content_travaux {
        width: 100%;
    }

    .travail {
        justify-content: center;
        align-content: center;
        align-items: center;
        width: 100% !important;
    }

    .img_travail {
        width: 100% !important;
        height: 300px;
        padding: 0 !important;
        margin-top: 20px;
        img {
            height: 100%;
            margin-top: 0 !important;
        }
    }

    .right_travail {
        width: 100% !important;
    }

    .item_travail {
        width: 100% !important;
    }

    .titre_travail {
        h1 {
            font-size: 20px !important;
        }

        p {
            font-size: 15px !important;
        }
    }

    .item_travail_item {
        width: 100% !important;
        margin-top: 20px;
        h2 {
            margin-top: 5px !important;
        }
    }
}

@media screen and (max-width: 750px) {
    .travail {
        flex-direction: column;
    }
}