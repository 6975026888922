.cursor {
    position: absolute;
    pointer-events: none;
    z-index: -1;
}

.content_cursor {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: all .5s ease-in-out;
    background: rgba(44, 44, 134, 0.37);
    box-shadow: 0 0 150px 300px rgba(44, 44, 134, 0.37);
}

.hoverContent > div {
    width: 100px;
    height: 100px;
    background-color: rgba(255, 255, 255, 0.21);
    border: 1px solid transparent;
}

@media screen and (max-width: 1000px) {
    .cursor {
        display: none
    }
}